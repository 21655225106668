.label {
    font-weight: bolder;
    color: #0285c7;
}

.submitButton {
    min-width: 100px;
    background-color: #018BD0;
    border: 0;
}

.submitButton:hover {
    background-color: #015bd0;
}


.login {
    border: 0;
    border-radius: 30px;
    padding: 30px 20px;
    max-width: 600px;
    box-shadow: 0 1px 22px 0 rgba(0, 140, 205, 0.1);
    margin: auto;
    margin-top: 90px;
}

.error {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    color: rgb(199, 0, 0);
    font-weight: 700;
}

