.contactUsBlock {
    padding: 30px;
    font-size: 25px;
    text-align: center;
    box-shadow: rgb(211, 211, 211) 7px 7px;
    user-select: none;
    cursor: pointer;
}

.blue:hover {
    background-color: #007cb9;
}

.yellow:hover {
    background-color: #f6c503;
}

.contactUsBlock:active {
    transform: translateY(3px) translateX(3px);;
    box-shadow: rgb(185, 185, 185) 4px 4px;
    transition: 0.03s;
}

.yellow {
    background-color: #fad301;
    color: black;
}

.blue {
    background-color: #018acf;
    color: white;
}