.content {
    margin-bottom: 5px;
    display: flex;
    gap: 8px;
}

.contentPrice {
    width: 30%;
    padding: 5px 20px;
    background-color: #f8d102;
}

.contentText {
    background-color: rgb(146, 198, 255);
    width: 66%;
    padding: 5px;
    margin-left: 4%;
}

@media (max-width: 916px) {
    .contentPrice {
        width: 40%;
    }
}

@media (max-width: 700px) {
    .content {
        flex-direction: column;
        align-items: center;
    }
    .contentPrice {
        width: 66%;
        margin-left: 4%;
    }
}

