.writeUs {
    text-align: center;
}

.contacts {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
}

.contactInfo {
    display: flex;
    flex-direction: column;
}

.contacts img {
    width: 220px;
}

.link {
   cursor: pointer;
}

.link:hover {
    color: #018acf;
}

.contacts b {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.04em;
    margin-bottom: 24px;
    margin-top: 40px;
}


.contacts p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.04em;
    width: 299px;
    height: 53px;
}

.contact {
    display: flex;
    gap: 19px;
    flex-direction: row;
    flex-wrap: wrap;
}

.contact a {
    height: 26px;
    font-size: 16px;
    letter-spacing: 0.04em;
    text-decoration: none;
    line-height: 130%;
}

@media (max-width: 768px) {
    .contacts {
        flex-direction: column;
        align-items: center;
    }

    .contactInfo {
        justify-content: center;
        align-items: center;
    }

    .contactInfo p {
        text-align: center;
    }
}