.image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.imageContainer {
    position: relative;
    overflow: hidden;
    height: 400px;
    border-radius: 30px;
}

.bluredImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(30px);
}