.mainTitleWithPrice{
    margin-bottom: 5px;
    display: flex;
    gap:8px;
}

.title {
    background-color: #018acf;
    width: 70%;
    padding: 10px;
    color: white;
}

.contentPrice {
    width: 30%;
    padding: 5px 20px;
    background-color: #f8d102;
}

@media (max-width: 916px) {
    .contentPrice {
        width: 40%;
    }
}

@media (max-width: 700px) {
    .mainTitleWithPrice {
        flex-direction: column;
        align-items: center;
    }
    .contentPrice {
        width: 70%;
    }
}

