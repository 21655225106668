.whyElement {
    justify-content: center;
    text-align: center;
    font-size: 22px;
}

.whyElement span {
    text-align: center;
    display: inline-block;
    max-width: 250px;
    font-weight: bolder;
}

.whyImageContainer {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 15px;
    transition: 0.4s;
    border: 5px solid;
    border-color: #018acf;
}

.whyImageContainer img {
    height: 100%;
    width: auto;
}

.whyImageContainer:hover {
    transform: rotate(360deg);
    transition: 0.4s;
}

@media (max-width: 992px) {
    .whyElement {
        margin-bottom: 20px;
    }
}

@media (max-width: 576px) {
    .whyElement {
        font-size: 16px;
    }

    .whyImageContainer {
        width: 80px;
        height: 80px;
    }
}