.calculateHeader {
    font-weight: bolder;
    font-size: 30px;
    margin-bottom: 10px;
}

.calculatorCard {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 1px 22px 0 rgba(0, 0, 0, 0.15);
    padding: 20px;
    margin: 80px auto;
    width: 80%;
    position: relative;
}

.label {
    margin-bottom: 5px;
}

.group {
    margin-bottom: 20px;
}

.choice {
    margin-left: 4px;
    margin-right: 20px;
}

.selectInput {
    max-width: 400px;
}

.totalPrice {
    font-weight: bolder;
    margin-left: 10px;
}

.totalPriceBlock {
    font-size: 25px;
}

.redStar {
    color: darkred;
}

.info {
    color: #a9adb1;
    margin-top: 20px;
    max-width: 900px;
}

.contactUsBlock {
    position: absolute;
    right: 20px;
    bottom: 20px;
    background-color: #fad301;
    border-radius: 5px;
    height: 50px;
    width: 150px;
    padding: 10px;
}

.contactUs {
    text-align: center;
    margin-top: auto;
    font-size: 20px;
}

.contactUsBlock:hover {
    background: #ffcc00;
    cursor: pointer;
}

@media (max-width: 485px) {
    .choiceBlock {
        display: inline-block;
    }
}

@media (max-width: 991px) {
    .contactUsBlock {
        position: initial;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 1199px) {
    .info {
        max-width: 500px;
    }
}

.tabLabel {
    color: #018acf;
    font-size: 20px
}

.headerImage {
    width: 250px;
}