.createDesignHeader {
    font-weight: bolder;
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 10px;
}

.designersHelp {
    font-weight: bolder;
    font-size: 30px;
    margin-top: -10px;
    color: #018acf;
}

.contactUs {
    width: 280px;
    margin-top: 20px;
}
