.pin {
    box-shadow: rgba(105, 105, 105, 0.28) 5px 5px;
    max-width: 500px;
    aspect-ratio: 1 / 1;
    user-select: none;
    margin: 150px auto;
    overflow: hidden;
    border-radius: 50%;
}

.content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.padding {
    padding: 60px 50px;
    aspect-ratio: 1 / 1;
    background-color: white;
}

.pinHeader {
    text-align: center;
    font-size: 50px;
    max-width: 800px;
    font-weight: bolder;
    z-index: 2;
    color: black;
}

.pinPhone {
    text-align: center;
    margin-top: 10px;
    font-size: 40px;
    max-width: 500px;
    z-index: 2;
    color: black;
}

.background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 140%;
    height: 140%;
    object-fit: contain;
    opacity: 20%;
}

@media (max-width: 575px) {
    .pin {
        max-width: 350px;
    }
    .padding {
        padding: 14px;
    }
    .pinHeader {
        font-size: 30px;
    }
    .pinPhone {
        font-size: 28px;
    }
}