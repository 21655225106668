.brand {
    font-size: 20px;
}

.navbar {
    padding: 10px 30px;
    background: linear-gradient(90deg, rgb(180, 225, 255), rgb(1, 139, 208) 30%);
    -webkit-box-shadow: 0px 6px 9px 5px rgba(1, 139, 208, 0.2);
    -moz-box-shadow: 0px 6px 9px 5px rgba(1, 139, 208, 0.2);
    box-shadow: 0px 6px 9px 5px rgba(1, 139, 208, 0.2);
    margin-top: 25px;
}

@media (max-width: 900px) {
    .navbar {
        background: linear-gradient(90deg, rgb(180, 225, 255), rgb(1, 139, 208) 400px);
    }
}


.navItem {
    color: white;
    font-size: 17px;
    margin-left: 20px;
    max-width: 180px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.5s;
}

@media (max-width: 1032px) {
    .navItem {
        font-size: 15px;
    }
}

@media (max-width: 991px) {
    .navItem {
        margin-left: 0;
        text-align: right;
        margin-left: auto;
    }
}

.navItem:hover {
    transform: scale(105%);
    transition: 0.5s;
    color: #ffdd00 !important;
}

.navItem:active, .navItem:focus {
    color: #ffdd00 !important;
    box-shadow: none !important;
}

.logo {
    height: 80px;
}

.createOwnDesign {
    max-width: 280px;
    background-color: #fad301;
    color: #000000;
    box-shadow: #0075ab 4px 4px;
    padding: 10px 20px;
    text-align: center;
}

.createOwnDesign:hover {
    transform: scale(100%) !important;
    color: black !important;
    background-color: #f4c304;
}

.createOwnDesign:focus, .createOwnDesign:active {
    color: black !important;
    box-shadow: none !important;
}