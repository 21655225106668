.readMore {
    padding: 5px;
    background-color: #018acf;
    width: 200px;
    color: white;
    border-radius: 10px;
    text-align: center;
    display: block;
    margin: auto;
}

.readMore:hover, .readMore:active, .readMore:focus {
    background-color: #015bd0;
}