.businessCard {
    background-color: #ffffff;
    box-shadow: rgba(105, 105, 105, 0.28) 5px 5px;
    max-width: 500px;
    aspect-ratio: 9 / 5;
    user-select: none;
    margin: 150px auto;
    overflow: hidden;
}

.content {
    position: relative;
    width: 100%;
    height: 100%;
}

.padding {
    padding: 20px;
    aspect-ratio: 9 / 5;
}

@media (max-width: 575px) {
    .businessCard {
        max-width: 350px;
    }
    .padding {
        padding: 14px;
    }
}