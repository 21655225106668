.slider {
    margin-top: 80px;
}

.whyMe,
.news,
.contactsMain, 
.aboutUs,
.productSlider {
    margin-top: 100px;
}

.title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 40px;
    color: #018acf;
}

.anyQuestionsTitle {
    margin-bottom: 20px;
}

.readMoreButton {
    margin-top: 20px;
}

.aboutUsText p{
    font-size: 20px;
}

.aboutUsText p:last-child {
    color: #0382c2;
    font-weight: bolder;
    margin-top: 10px;
    display: inline-block;
}

.aboutUsText p span {
    font-weight: bold;
}