.canvas {
    background-color: rgb(235, 243, 252);
}

.parameters {
    background-color: #ffffff;
    height: 600px;
    overflow-y: scroll;
}

.editor {
    box-shadow: 0 1px 22px 0 rgba(0, 140, 205, 0.15);
    border-radius: 30px;
}

.contactUsBlock {
    margin: 30px auto;
    width: 300px;
}

.contactUs {
    padding: 10px;
    font-size: 20px;
    text-align: center;
    box-shadow: rgb(211, 211, 211) 7px 7px;
    user-select: none;
    cursor: pointer;
    background-color: #018acf;
    color: white;
}

.contactUs:active {
    transform: translateY(3px) translateX(3px);;
    box-shadow: rgb(185, 185, 185) 4px 4px;
    transition: 0.03s;
}

.contactUs:hover {
    background-color: #007cb9;
}