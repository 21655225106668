.mainTitle {
    margin-bottom: 5px;
    display: flex;
    gap: 8px;
}

.title {
    background-color: #018acf;
    width: 70%;
    padding: 10px;
    color: white;
}

.space {
    width: 30%;
    padding: 5px 5px 5px 20px;
}

@media (max-width: 700px) {
    .mainTitle {
        flex-direction: column;
        align-items: center;
    }
    .space {
        display: none;
    }

}

@media (max-width: 916px) {
    .space {
        width: 40%;
    }
}