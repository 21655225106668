.createPost {
    min-height: 45px;
    background-color: #018BD0;
    border: 0;
    margin-top: 35px;
    min-width: 230px;
}

.deletePost,
.editPost {
    min-width: 100px;
    min-height: 45px;
    border: 0;
}

.deletePost {
    background-color: #fc3e3e;
}

.editPost {
    background-color: #018BD0;
}

.editPost:hover {
    background-color: #186ddd;
}

.deletePost:hover {
    background-color: #e82525;
}

.createPost:hover {
    background-color: #186ddd;
}


.card {
    width: 70%;
    border: 0;
    border-radius: 10px;
    box-shadow: 0 1px 22px 0 rgba(0, 0, 0, 0.15);
    padding: 20px;
    margin-top: 30px;
}

.container {
    flex-direction: column;
    display: flex;
    align-items: center;
}


.buttons {
    display: flex;
    justify-content: end;
    gap: 20px;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}
