.error {
    text-align: center;
    font-size: 9em;
    line-height: 1.3em;
}

.errorText {
    text-align: center;
    font-size: 2em;
    line-height: 1.15em;
}

.heading {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    overflow: auto;
    z-index: -100;
}