.newsText {
    text-indent: 40px;
    font-weight: 300;
    padding: 10px;
    margin-top: 10px;
}

.title {
    font-weight: 600;
    color: #007cb9;
}

.date {
    font-size: 15px;
    color: gray;
}

.imageContainer {
    margin-top: 20px;
}