.sliderImage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.sliderBlock {
    position: relative;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 3;
    background-color: #ffffff;
}

.sliderBlur {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(50px);
}

.slider {
    width: 85%;
    margin: 0 auto;
}

@media (max-width: 767px) {
    .slider {
        width: 100%;
    }
}