.contactForm {
    padding: 56px;
    width: 540px;
    background: #FFFFFF;
    box-shadow: 0 1px 22px 0 rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.contactForm h3 {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.04em;
    margin-bottom: 48px;
    display: inline-block;
}

.inputClass div input {
    width: 100%;
    height: 72px;
    left: 670px;
    top: 608px;
    border: 1px solid #000000;
    border-radius: 10px;
    padding: 21px 0px 21px 36px;
    margin-bottom: 32px;
    font-size: 20px;
    outline: none;
}

.inputClass div input::placeholder,
.inputClass div textarea::placeholder {
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.04em;
}

.inputClass textarea {
    width: 100%;
    height: 144px;
    border: 1px solid #000000;
    border-radius: 10px;
    margin-bottom: 72px;
    padding: 21px 0px 21px 36px;
    font-size: 20px;
    outline: none;
}

.contactUsButtonBlock {
    width: 70%;
    margin: auto;
}

@media (max-width: 768px) {
    .contactForm {
        margin-top: 40px;
    }
}

@media (max-width: 710px) {
    .contactForm {
        width: 470px;
        padding: 20px;
    }

    .buttonSend {
        width: 100%;
    }
}


@media (max-width: 500px) {
    .contactForm {
        width: 100%;
        margin: 57px 17px 96px 17px;
    }
}