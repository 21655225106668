.calculateCard {
    background-color: #018acf;
    padding: 15px;
    color: white;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    transition: 0.4s;
    user-select: none;
    cursor: pointer;
}

.yellow {
    background-color: #fad301;
    width: 100%;
    height: 200px;
    margin: auto;
}

.calculateCard:hover {
    transform: translateY(-10px);
    transition: 0.4s;
}

.calculateCardHeader {
    position: absolute;
}

.calculateImage {
    position: absolute;
    top: -30%;
    left: 50%;
    rotate: -25deg;
    height: 350px;
    width: 350px;
}

.calculateCardText {
    font-size: 15px;
    position: absolute;
    max-width: 150px;
    margin-top: 10px;
}

.calculateCardName {
    font-size: 30px;
    max-width: 250px;
    line-height: 35px;
}