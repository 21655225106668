.name {
    font-size: 27px;
    font-weight: bolder;
    margin-left: auto;
    max-height: 120px;
    overflow: hidden;
}

.jobTitle {
    font-size: 15px;
    max-height: 20px;
    overflow: hidden;
}

.informationBlock {
    color: #5d5d5d;
    font-size: 10px;
    position: absolute;
    bottom: 0;
    text-align: right;
    max-width: 200px;
    right: 0;
    overflow: hidden;
    max-height: 120px;
}

.header {
    font-weight: bolder;
}

.background {
    background: url("../../../../../images/businessCardSamples/businessCardSample2.png");
    background-size: 100%;
}

.mainInf {
    max-width: 300px;
    margin-left: auto;
    text-align: right;
    overflow: hidden;
}

@media (max-width: 575px) {
    .name {
        font-size: calc(27px * 0.7);
        max-height: calc(120px * 0.7);
    }

    .informationBlock {
        font-size: calc(10px * 0.7);
        max-width: calc(200px * 0.7);
        max-height: calc(120px * 0.7);
    }

    .jobTitle {
        font-size: calc(15px * 0.7);
        max-height: calc(20px * 0.7);
    }

    .mainInf {
        max-width: calc(300px * 0.7);
    }
}