.sendButton {
    background-color: #fad301;
    margin-left: 10px;
}

.sendButton:hover {
    background-color: #f4c304;
}

.closeModalButton:hover {
    background-color: #e7eaee;
}

.button {
    border: none;
    padding: 10px;
    border-radius: 10px;
}

.inputBlock {
    margin-bottom: 20px;
}

.fileBlock {
    text-align: center;
    border: 1px dotted lightgray;
    border-radius: 15px;
    user-select: none;
    width: 200px;
    margin: auto;
}

.fileHeader {
    display: inline;
}

.fileName {
    display: inline;
    margin-left: 10px;
}


