.priceEditorHeader {
    font-weight: bolder;
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 10px;
}

.pricesBlock {
    margin-top: 30px;
}

.form {
    background-color: #018acf;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.item {
    margin: auto;
    font-weight: bolder;
    color: white;
}

.button {
    background-color: white;
    height: 100%;
    border-radius: 10px;
    padding: 5px;
    font-size: 15px;
    border: none;
    cursor: pointer;
    width: 100%;
}
