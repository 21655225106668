.block {
    background-color: #fad301;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    cursor: pointer;
}

.block:hover {
    background-color: #f4c304;
}
