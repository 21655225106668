.contactsMain {
    margin-top: 45px;
}

.map {
    margin-top: 55px;
    display: flex;
    justify-content: center;
}

.contactsPage {
    display: flex;
    justify-content: space-evenly;
    margin-top: 60px;
    background: #FFFFFF;
    box-shadow: 0 1px 22px 0 rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 56px 20%;
}

.contactForm {
    width: 50%;
    margin-top: 20px;
}

.contactForm h3 {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.04em;
    margin-bottom: 25px;
    display: inline-block;
}

.inputClass div input {
    width: 100%;
    border: 1px solid #919191;

    padding: 10px 0px 10px 16px;
    margin-bottom: 32px;
    font-size: 20px;
    outline: none;
}

.inputClass div input::placeholder,
.inputClass div textarea::placeholder {
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.04em;
}

.inputClass textarea {
    width: 100%;
    border: 1px solid #919191;
    margin-bottom: 40px;
    padding: 10px 0px 10px 16px;
    font-size: 20px;
    outline: none;
}

.contactInfo {
    display: flex;
    flex-direction: column;
}


.link {
    cursor: pointer;
}

.link:hover {
    color: #018acf;
}

.contactInfo b {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.04em;
    margin-bottom: 20px;
    margin-top: 20px;
}


.contactInfo p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.04em;
    width: 299px;
    height: 53px;
}

.contact {
    display: flex;
    gap: 20px;

}

.contact a {
    height: 26px;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.04em;
    text-decoration: none;
}

.contactUsButtonBlock {
    display: flex;
    align-items: center;
    justify-content: center;
}


.buttonSend {
    padding: 10px;
    font-size: 25px;
    width: 50%;
    box-shadow: rgb(211, 211, 211) 7px 7px;
    user-select: none;
    cursor: pointer;
    background-color: #fad301;
    color: black;
    border: none;
}

.buttonSend:active {
    transform: translateY(3px) translateX(3px);;
    box-shadow: rgb(185, 185, 185) 4px 4px;
    transition: 0.03s;
}

.buttonSend:hover {
    background-color: #f6c503;
}


@media (max-width: 768px) {
    .contactsPage {
        flex-direction: column;
        align-items: center;
    }

    .contactInfo {
        justify-content: center;
        align-items: center;
    }

    .contactInfo p, .contactInfo b, .contactForm h3 {
        text-align: center;
    }

    .contactForm {
        margin-top: 40px;
        width: 100%;
    }
}

@media (max-width: 1015px) {
    .buttonSend{
        width: 100%;
    }
}

@media (max-width: 1276px) {
    .fileSmall {
        width: 200px;
        margin: 0 auto;
    }
}
