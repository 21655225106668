.sampleBlock {
    display: inline-block;
    margin: 0 10px;
}

.sampleBlockImage {
    width: 180px;
    height: 100px;
}

.sampleBlockSelected {
    border: 5px solid #ffdd00;
}