body {
    background-image: url("./images/background.png");
    margin: 0;
    background-attachment: fixed;
    background-size: cover;
    padding-bottom: 80px;
}

body:before {
    content: '';
    display: block;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: -100;
}

* {
    font-family: 'Montserrat', sans-serif;
}

.carousel-inner {
    border-radius: 30px;
    overflow: hidden;
}

#productSlider>.carousel-inner {
    border-radius: 0;
}

.spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 200px;
    height: 200px;
}

img {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
}

#productSlider>.carousel-control-next>.carousel-control-next-icon {
    background-color: #018acf;
    border-radius: 10px;
    padding: 20px;
}

#productSlider>.carousel-control-prev>.carousel-control-prev-icon {
    background-color: #018acf;
    border-radius: 10px;
    padding: 20px;
}