.slider {
    height: 370px;
    padding: 40px 100px;
    border-radius: 0;
}

.flex {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
}

.SliderElement {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 22px;
    border-radius: 0;
}

.SliderElement span {
    text-align: center;
    display: inline-block;
    width: 150px;
    font-size: 15px;
    height: 50px;
    margin-bottom: 10px;
}

.SliderContainer {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 5px;
    transition: 0.4s;
    background-color: #018acf;
    padding: 10px;
}

.SliderContainer img {
    height: 100%;
    width: auto;
}



@media (max-width: 992px) {
    .SliderElement {
        margin-bottom: 20px;
    }
    .SliderElement span {
        width: 80px;
        font-size: 9px;
        height: 2px;
    }
    .SliderContainer {
        width: 80px;
        height: 80px;
    }
    .slider {
        height: 300px;
        padding: 30px 80px;
    }
}

@media (max-width: 767px) {
    .SliderElement {
        margin-bottom: 20px;
    }
    .SliderElement span {
        width: 50px;
        font-size: 8px;
        height: 2px;
    }
    .SliderContainer {
        width: 50px;
        height: 50px;
    }
    .slider {
        height: 230px;
        padding: 25px 70px;
    }
}