.newsCard {
    width: 80%;
    border: 0;
    box-shadow: 0 1px 22px 0 rgba(0, 0, 0, 0.15);
    padding: 20px;
    margin: 0 auto;
    position: relative;
    border-radius: 10px;
    background-color: white;
}

.newsHeader {
    font-size: 28px;
    margin-bottom: 10px;
    font-weight: bolder;
}


.date {
    color: #c0c0c0;
    font-size: 12px;
    position: absolute;
    right: 25px;
}

@media (max-width: 767px) {
    .date {
        font-size: 12px;
        position: absolute;
        left: 22px;
        top: 265px;
    }

    .newsHeader {
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .newsCard {
        width: 100%;
    }
}

.newsImageContainer {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 200px;
    max-height: 200px;
    border-radius: 5px;
}

.newsImageContainer img {
    height: 100%;
    width: auto;
}

.newsText {
    width: 70%;
    height: 100%;
    text-indent: 40px;
    margin-top: 20px;
}

.newsTextContainer {
    margin: 0;
}