.name {
    font-size: 27px;
    font-weight: bolder;
    max-height: 120px;
    overflow: hidden;
    margin-top: 20px;
    margin-left: 30px;
}

.jobTitle {
    font-size: 15px;
    max-height: 20px;
    overflow: hidden;
    margin-left: 30px;
}

.informationBlock {
    color: white;
    font-size: 10px;
    position: absolute;
    bottom: 0;
    text-align: right;
    max-width: 250px;
    right: 0;
    overflow: hidden;
    max-height: 120px;
}

.header {
    font-weight: bolder;
}

.background {
    background-color: black;
    background-size: 100%;
}

.mainInf {
    color: white;
    max-width: 300px;
    margin-right: auto;
    text-align: left;
    overflow: hidden;
}

@media (max-width: 575px) {
    .name {
        font-size: calc(27px * 0.7);
        max-height: calc(120px * 0.7);
        margin-left: calc(30px * 0.7);
        margin-top: calc(20px * 0.7);
    }

    .informationBlock {
        font-size: calc(10px * 0.7);
        max-width: calc(200px * 0.7);
        max-height: calc(120px * 0.7);
    }

    .jobTitle {
        font-size: calc(15px * 0.7);
        max-height: calc(20px * 0.7);
        margin-left: calc(30px * 0.7);
    }

    .mainInf {
        max-width: calc(300px * 0.7);
    }
}