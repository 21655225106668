.designCard {
    background-color: #fcd500;
    padding: 15px;
    color: #21252a;
    height: 200px;
    width: 95%;
    overflow: hidden;
    position: relative;
    box-shadow: #018acf 10px 10px;
    font-weight: bolder;
    transition: 0.4s;
    margin: auto;
    user-select: none;
    cursor: pointer;
}

.designCard:hover {
    transform: scale(104%);
    transition: 0.4s;
}

.designCardHeader {
    font-size: 30px;
    position: absolute;
    max-width: 250px;
}

.designImage {
    position: absolute;
    top: -30%;
    left: 50%;
    rotate: -25deg;
    height: 350px;
    width: 350px;
}

.designCard:active {
    transform: scale(104%) translateY(4px) translateX(4px);
    box-shadow: #018acf 6px 6px;
    transition: 0.1s;
}

