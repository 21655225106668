.layoutsAndDesign h1, .layoutsAndDesign h4 {
    text-align: center;
}

.layoutsAndDesign h1 {
    font-weight: bolder;
    margin: 30px 0;
}

.layoutsAndDesign h4 {
    margin-bottom: 15px;
}

.container {
    margin: auto;
    width: 80%;
    margin-bottom: 20px;
}

.downloadFile {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:50px;
}

.downloadFile a {
    text-decoration: none;
    outline: none;
    color: black;
    padding: 15px 25px;
    font-size: 20px;
}


.btnDownload {
    padding: 15px;
    text-align: center;
    box-shadow: rgb(211, 211, 211) 7px 7px;
    user-select: none;
    cursor: pointer;
    background-color: #fad301;
    color: black;
    border: none;
}


.btnDownload:hover {
    background-color: #f6c503;
}

.btnDownload:active {
    transform: translateY(3px) translateX(3px);;
    box-shadow: rgb(185, 185, 185) 4px 4px;
    transition: 0.03s;
}


@media (max-width: 700px) {
    .container {
       width: 100%;
    }
}


