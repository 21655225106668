.card {
    width: 70%;
    border: 0;
    border-radius: 10px;
    box-shadow: 0 1px 22px 0 rgba(0, 0, 0, 0.15);
    padding: 20px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

@media (max-width: 800px) {
    .card {
        width: 100%;
    }
}