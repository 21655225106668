.header {
    color: #018acf;
    text-align: center;
    font-size: 18px;
    max-height: 60px;
    overflow: hidden;
}

.name {
    font-size: 27px;
    font-weight: bolder;
    margin: 20px 0 auto;
    text-align: center;
    max-height: 80px;
    overflow: hidden;
}

.jobTitle {
    margin: 10px auto;
    text-align: center;
    font-size: 14px;
    max-height: 40px;
    overflow: hidden;
}

.informationBlock {
    color: #575757;
    font-size: 12px;
    position: absolute;
    bottom: 0;
    max-height: 80px;
    overflow: hidden;
}

.background {
    background: url("../../../../../images/businessCardSamples/businessCardSample1.png");
    background-size: 100%;
}

.mainInf {
    margin-top: 50px;
}

@media (max-width: 575px) {
    .header {
        font-size: calc(18px * 0.7);
        max-height: calc(60px * 0.7);
    }

    .name {
        font-size: calc(27px * 0.7);
        font-weight: bolder;
        margin: calc(20px * 0.7) 0 auto;
        text-align: center;
        max-height: calc(80px * 0.7);
    }

    .informationBlock {
        font-size: calc(12px * 0.7);
        max-height: calc(80px * 0.7);
    }

    .jobTitle {
        margin: calc(10px * 0.7) auto;
        font-size: calc(14px * 0.7);
        max-height: calc(40px * 0.7);
    }

    .mainInf {
        margin-top: calc(50px * 0.7);
    }
}