.parametersBody {
    padding: 10px;
}

.chooseSampleBlock {
    overflow-x: scroll;
    border-radius: 10px 10px 0 0;
    border: dashed 1px gray;
    margin-bottom: 30px;
}

.scrolledBody {
    width: 830px;
    padding: 15px;
    background: #e9f1fb;
}

.parameterName {
    font-size: 20px;
    margin-bottom: 10px;
    color: #018acf;
}