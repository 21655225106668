.name {
    font-size: 27px;
    font-weight: bolder;
    max-height: 120px;
    overflow: hidden;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 10px;
}

.jobTitle {
    font-size: 15px;
    max-height: 20px;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.informationBlock {
    color: white;
    font-size: 10px;
    text-align: center;
    overflow: hidden;
    max-height: 80px;
    margin: 20px auto;
}

.header {
    font-weight: bolder;
}

.background {
    background: -webkit-linear-gradient(45deg, rgb(143, 255, 230), rgb(44, 48, 167));
    background: -moz-linear-gradient(45deg, rgb(143, 255, 230), rgb(44, 48, 167));
    background: linear-gradient(45deg, rgb(143, 255, 230), rgb(44, 48, 167));
    background-size: 100%;
}

.mainInf {
    color: white;
    overflow: hidden;
}

.border {
    border: solid 1px white;
}

@media (max-width: 575px) {
    .name {
        font-size: calc(27px * 0.7);
        max-height: calc(120px * 0.7);
        padding: calc(10px * 0.7);
        margin-top: calc(10px * 0.7);
    }

    .informationBlock {
        font-size: calc(10px * 0.7);
        max-height: calc(80px * 0.7);
    }

    .jobTitle {
        font-size: calc(15px * 0.7);
        max-height: calc(20px * 0.7);
    }
}