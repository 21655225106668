.createBanner {
    min-width: 100px;
    min-height: 45px;
    background-color: #018BD0;
    border: 0;
    margin-top: 35px;
}

.deleteBanner, .editBanner {
    min-width: 100px;
    min-height: 45px;
    border: 0;
}

.deleteBanner {
    background-color: #fc3e3e;
}

.editBanner {
    background-color: #018BD0;
}

.editBanner:hover {
    background-color: #186ddd;
}

.deleteBanner:hover {
    background-color: #e82525;
}

.createBanner:hover {
    background-color: #186ddd;
}

.containerBanner {
    position: relative;
    overflow: hidden;
    height: 250px;
    width: 60%;
    border-radius: 7px;

}

.banner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card {
    margin-top: 30px;
    border: 0;
    border-radius: 30px;
    box-shadow: 0 1px 22px 0 rgba(0, 140, 205, 0.15);
    display: flex;
    flex-direction: row;
    justify-content:center;
    gap: 40px;
    flex-wrap: wrap;
    padding: 20px ;
}

.buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:15px;
}
